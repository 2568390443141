import React from 'react'
import ReactDOM from 'react-dom'

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const label = this.props.label;

    return(
      <div className="progress">
        <div className="progress-bar bg-primary progress-bar-striped progress-bar-animated"  style={{width: '100%'}}>
          { label ? label : this.props.children }
        </div>
      </div>
    )
  }
}