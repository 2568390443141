import React from 'react'
import ReactDOM from 'react-dom'

export default class ValidatedTableInput extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: "",
    };
  }

  handleChange(event) {
    const value = event.target.value;
    this.props.onValueChange(value);
  }

  render() {
    const value = this.props.value
    const validationErrorMessage = this.props.validationErrorMessage;
    let validationClass = "";
    if (validationErrorMessage) {
      validationClass = "is-invalid";
    }

    return(
      <React.Fragment>
        <input value={value} onChange={this.handleChange} className={"form-control form-control-sm " + validationClass} />        
      </React.Fragment>
    )
  }
}