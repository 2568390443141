import React from 'react'
import ReactDOM from 'react-dom'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import theme from './highcharts_theme'
import ProgressBar from './progress_bar'

import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";

// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

export default class StockChartFromApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      series: []
    };
  }

  afterChartCreated(chart) {
    setTimeout(function() {
      chart.reflow();
    }, 50);
  }

  componentDidMount() {
    const url = this.props.url;

    fetch(url)
      .then(res => res.json())
      .then(
        (response) => {
          this.setState({
            isLoaded:true,
            series: response
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }

  render() {
    const { error, isLoaded, series } = this.state;
    const opt = this.props.options;
    Highcharts.theme = theme;
    Highcharts.setOptions(Highcharts.theme);

    if (error) {
      console.log(error.message);
      return <div>There was an error loading the chart data.</div>
    } else if (!isLoaded) {
      return (
        <ProgressBar>Loading {opt.title.text}</ProgressBar>
      )
    } else {
      const options = Object.assign({}, opt, {series: series});
      return (
        <React.Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            constructorType={"stockChart"}
            callback={this.afterChartCreated} />
        </React.Fragment>
      );
    }
  }
}