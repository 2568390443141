import React from 'react'
// import ReactDOM from 'react-dom'
import * as ReactDOMClient from 'react-dom/client';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import theme from './highcharts_theme'
import ProgressBar from './progress_bar'
import Utilities from './utilities'

export default class EstimatesChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      options: {
        title: {
          text: 'Estimates'
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {enabled: false},
          labels: {
            formatter: function() {
               return this.value*100.0+"%";
            }
          }
        },
        plotOptions: {
          series: {
            lineWidth: 0,
            states: {
              hover: {
                lineWidthPlus: 0
              }
            }
          }
        },
        tooltip: {
          formatter: function() {
            return '<b>' + this.series.name + '</b>: ' + Highcharts.numberFormat(this.y * 100, 1) + ' %';
          }
        },
        series: [],
        categories: []
      }
    };
  }

  afterChartCreated(chart) {
    setTimeout(function() {
      chart.reflow();
    }, 50);
  }

  componentDidMount() {
    const url = this.props.url;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded:true,
            series: result.series,
            categories: result.categories
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }


    render() {
    const { error, isLoaded, series, categories } = this.state;
    const baseOptions = this.state.options;
    const specificOptions = this.props.options;
    let options = null;
    Highcharts.theme = theme;
    Highcharts.setOptions(Highcharts.theme);

    if (error) {
      console.log(error.message);
      return <div>There was an error loading the chart data.</div>
    } else if (!isLoaded) {
      return (
        <ProgressBar>Loading {specificOptions.title.text}</ProgressBar>
      )
    } else {

      let xAxis = null;
      xAxis = Object.assign({}, specificOptions.xAxis, {categories:categories})

      options = Object.assign({}, baseOptions, specificOptions);
      // options = Object.assign({}, options, {series: series, xAxis: {categories: categories}});
      options = Object.assign({}, options, {series: series, xAxis: xAxis});
      return (
        <React.Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </React.Fragment>
      );
    }
  }
}




