import React from 'react'
import ReactDOM from 'react-dom'

export default class ValidatedInput extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: "",
    };
  }

  handleChange(event) {
    const value = event.target.value;
    this.props.onValueChange(value);
  }

  render() {
    const value = this.props.value
    const validationErrorMessage = this.props.validationErrorMessage;
    const lableText = this.props.placeHolder;
    let validationClass = "";
    let tooltipText = this.props.tooltipText;
    if (validationErrorMessage) {
      validationClass = "is-invalid";
    }

    return(
      <React.Fragment>
        <div className="form-floating">
          <input value={value} onChange={this.handleChange} className={"form-control " + validationClass} data-bs-trigger="hover" data-bs-toggle="tooltip" title={tooltipText} />
          <label>{lableText}</label>
        </div>
      </React.Fragment>
    )
  }
}